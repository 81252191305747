@mixin tagListItem {
  @include buttonStyle;
  padding: 2px 4px;
}

@mixin buttonStyle {
  background: var(--app-tertiary);
  color: var(--app-font-color);
  border: none;
  padding: 5px 8px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: var(--app-hover);
  }
  &.active {
    background: var(--app-active)
  }
}