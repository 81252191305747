@import './mixins.scss';

.file-preview {
  border: var(--file-border);
  cursor: pointer;
  transition: all .5s;
  background: var(--file-thumb-bg);
  position: relative;

  &.selected {
    opacity: .5;
  }

  input[type=checkbox] {
    position: absolute;
    left: 10px;
    top: 10px;
    display: none;
    z-index: 2;
    width: 20px;
    height: 20px;
  }

  &:hover, &.checked {
    input[type=checkbox] {
      display: inherit;
    }
  }

  .directory {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }

  .image-wrap {
    position: relative;
    padding: 8px 8px 100%;
    background: var(--file-thumb-file-bg);
    margin-bottom: 10px;

    .duration {
      position: absolute;
      bottom: 5px;
      right: 5px;
    }

    img {
      height: 100%;
    }

    .image, .gif {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding-bottom: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .taglist.main {
    position: absolute;
    left: 15px;
    bottom: 25px;
    z-index: 2;
  }

  &.with-directory {
    .taglist.main {
      bottom: 40px;
    }
  }

  .duration, .taglist li {
    @include tagListItem;
  }

  &:hover {
    border: var(--file-hover-border);
    box-shadow: var(--file-hover-shadow);
  }
}