.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 0 18px 0;

  & > .modal-header {
    background: #efefef;
    padding: 5px 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    & > * {
      display: block;
    }
  }

  .modal-content {
    padding: 10px;
  }
}

body.dark .modal {
  background: #131313;
  box-shadow: none;
  border: 2px solid #3e3e3e;

  & > .modal-header {
    background: #131313;
    color: #fff;
  }
}