.directory-list {
    width: 100%;

    .file-list.directories {
        grid-template-columns: repeat(2, 1fr);

        @media screen and (min-width: 400px) { grid-template-columns: repeat(3, 1fr); }
        @media screen and (min-width: 600px) { grid-template-columns: repeat(4, 1fr); }
        @media screen and (min-width: 800px) { grid-template-columns: repeat(5, 1fr); }
        @media screen and (min-width: 1000px) { grid-template-columns: repeat(6, 1fr); }
        @media screen and (min-width: 1200px) { grid-template-columns: repeat(7, 1fr); }
        @media screen and (min-width: 1400px) { grid-template-columns: repeat(8, 1fr); }
        @media screen and (min-width: 1600px) { grid-template-columns: repeat(9, 1fr); }
        @media screen and (min-width: 1800px) { grid-template-columns: repeat(10, 1fr); }
        @media screen and (min-width: 2000px) { grid-template-columns: repeat(11, 1fr); }
        @media screen and (min-width: 2200px) { grid-template-columns: repeat(12, 1fr); }
        @media screen and (min-width: 2400px) { grid-template-columns: repeat(13, 1fr); }
        @media screen and (min-width: 2600px) { grid-template-columns: repeat(14, 1fr); }
        @media screen and (min-width: 2800px) { grid-template-columns: repeat(15, 1fr); }
        @media screen and (min-width: 3000px) { grid-template-columns: repeat(16, 1fr); }

        a {
            display: block;
            padding: var(--dir-preview-padding);
            position: relative;
            width: 100%;
            height: 100%;
            border: 2px solid transparent;
            transition: border-color .3s;
            min-height: 160px;

            .layer {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                background: var(--dir-carpet-bg);
                opacity: 1;
                transition: opacity .3s;

                &, * {
                    color: var(--dir-text);
                }

                svg {
                    width: calc(100% - 80px);
                    height: calc(100% - 80px);
                    margin: 30px 40px;
                    pointer-events: none;

                    * {
                        fill: var(--dir-icon);
                    }
                }

                .name {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 0 10px 16px;
                    width: 100%;
                    text-align: center;
                    font-weight: bold;
                    font-size: 14px;

                    .meta {
                        font-size: 10px;
                        margin-top: 4px;
                    }
                }
            }

            &:hover, &.hover {
                &.has-preview .layer {
                    opacity: 0;
                }
                border-color: var(--dir-hover-border);
            }

            .preview {
                width: 100%;
                height: 0;
                padding-bottom: 100%;
                position: relative;
                background: var(--dir-preview-bg);

                .preview-file {
                    position: absolute;
                    width: 50%;
                    height: 50%;
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;

                    &:nth-child(1) { top: 0; left: 0 }
                    &:nth-child(2) { top: 0; right: 0 }
                    &:nth-child(3) { bottom: 0; left: 0 }
                    &:nth-child(4) { bottom: 0; right: 0 }
                }
            }
        }
    }
}