@import "mixins";

@keyframes fading {
  0% { opacity: .4 }
  50% { opacity: .6 }
  100% { opacity: .4 }
}

.video-list {
  .selection-menu {
    float: right;
    text-align: right;
    margin-bottom: 10px;

    position: sticky;
    top: 65px;
    z-index: 2;
    padding: 10px;
    transform: translateX(20px);

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      opacity: .5;
      background: #000;
      z-index: -1;
    }

    .cnt {
      margin-top: 10px;
    }
  }

  .sort-icon {
    display: inline-block;
    margin-right: 10px;
    font-size: 20px;
    margin-bottom: 10px;

    & + select {
      transform: translateY(-2px);
    }
  }
}

.file-list {
  list-style-type: none;
  margin: 20px auto;
  display: grid;
  padding: 0;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: 2px;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: 300px) { grid-template-columns: repeat(2, minmax(0, 1fr)); }
  @media screen and (min-width: 600px) { grid-template-columns: repeat(3, minmax(0, 1fr)); }
  @media screen and (min-width: 900px) { grid-template-columns: repeat(4, minmax(0, 1fr)); }
  @media screen and (min-width: 1200px) { grid-template-columns: repeat(5, minmax(0, 1fr)); }
  @media screen and (min-width: 1500px) { grid-template-columns: repeat(6, minmax(0, 1fr)); }
  @media screen and (min-width: 2000px) { grid-template-columns: repeat(7, minmax(0, 1fr)); }
  @media screen and (min-width: 2500px) { grid-template-columns: repeat(8, minmax(0, 1fr)); }
  @media screen and (min-width: 3000px) { grid-template-columns: repeat(9, minmax(0, 1fr)); }
  @media screen and (min-width: 3500px) { grid-template-columns: repeat(10, minmax(0, 1fr)); }

  &.loading {
    animation: fading 1.5s infinite;
    pointer-events: none;
  }

  li {
    display: block;
    box-sizing: border-box;
  }

  .file-preview.not-selected {
    opacity: .5;
  }

  & > li a.file {
    display: block;
    width: 100%;
    text-align: center;
    color: #000;
    text-decoration: none;
    padding: 10px;
    box-sizing: border-box;
    transition: all .3s;

    &.selected {
      opacity: .3;
    }

    img {
      max-width: 100%;
      max-height: 240px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: all .5s;

      &.loading {
        opacity: .5;
      }
    }

    * {
      transition: color .5s;
    }

/*
    &:hover {
      //transform: scale(1.1);
      //box-shadow: 0 0 30px -1px #fff;
      background: #000;

      * {
        color: #fff;
      }
    }
*/
  }
}

.file-type-selection {
  width: 100%;
  text-align: center;

  a {
    display: inline-block;
    @include buttonStyle;
  }
}

.content.has-selected-video .file-list {
  li {
    width: 100%;
  }
}
