.video-layer {
  z-index: 3;
  height: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height:100%;
    background:var(--app-tertiary);
    opacity: .5;
    z-index: -1;
  }

  .inner {
    width: 100%;
    height: 100%;
    background: var(--video-layer-bg);
    padding: 10px;
    box-shadow: var(--video-layer-shadow);
    border: var(--video-layer-border);
  }

  .content {
    display: flex;
    height: 100%;
  }

  header {
    display: flex;
    justify-content: space-between;
    padding: 0 0 10px;
    position: relative;
    z-index: 3;

    .taglist.main {
      transform: translate(-5px, 5px);
    }

    #btn-close {
      display: block;

      svg {
        height: 24px;
        margin-right: 8px;
      }
    }
  }

  &, * {
    box-sizing: border-box;
  }

  .file-list {
    grid-template-columns: 1fr;
    width: 300px;
    overflow-y: auto;
    margin-right: 20px;
  }

  .video-container, .file-list {
    margin-top: 50px;
    max-height: calc(100vh - 170px);

    @media screen and (max-width: 1000px) {
      max-height: 100%;
    }
  }

  .settings {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        padding: 0 10px;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    padding: 60px 0 0;
    width: 100%;

    .inner {
      border: none !important;
    }

    .content {
      padding: 70px 0 50px;
      width: 100%;
    }

    img {
      height: auto !important;
    }
  }

  .content {
    .container {
      width: calc(100%);
      margin-left: 20px;
      text-align: center;
      position: relative;
      display: flex;

      @media screen and (max-width: 1400px) {
        flex-direction: column;
        width: calc(100% - 40px);

        .base > div {
          height: calc(100% - 70px);
        }

        .video-list {
          display: none;
        }

        img {
          height: auto !important;
          max-height: calc(100% -  70px);
        }
      }

      @media screen and (max-width: 1000px) {
        margin: 0;
        width: 100%;
      }

      .base {
        width: 100%;
        height: 100%;
        position: relative;

        & > div {
          width: 100%;
          height: 100%;
        }
      }

      .video-list {
        width: 300px;
        flex-wrap: wrap;
        flex-shrink: 0;
      }

      video {
        width: 100%;
        height: 100%;
        background: var(--video-layer-video-bg);
      }

      img {
        height: calc(100% - 80px);
        max-width: 100%;
        margin-top: 60px;
      }

      nav a {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0 30px;
        opacity: 0;
        pointer-events: none;
        transition: opacity .5s;
        z-index: 3;
        color: #fff;
        font-size: 50px;

        &:first-child {
          left: 0;
        }

        &:last-child {
          right: 0;
        }
      }
    }
  }

  &.hover nav a {
    opacity: 1 !important;
    pointer-events: all !important;
  }
}

body.dark .video-layer {
  video {
    background: #fff;
  }
}