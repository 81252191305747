.video-container {
  overflow: hidden;

  video {
    background: #000;
  }

  .video-loading-error {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .message {
      text-align: center;
      color: #fff;
      font-size: 20px;
    }
  }

  .controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 80px 0 0;

    .controls-content {
      .control-buttons {
        opacity: 0;
        pointer-events: none;
        transition: all .25s;
      }
    }

    .control-buttons {
      display: flex;
      justify-content: space-between;
      height: 0;
      overflow: hidden;

      button {
        background: none;
        border: none;
        padding: 5px 8px;
        cursor: pointer;
        font-size: 16px;
        width: 40px;
      }

      .volume-container {
        display: inline-block;

        .input-wrap {
          height: 100%;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
        }

        input {
          opacity: 0;
          transition: all .5s;
          pointer-events: none;
          width: 100px;
        }
      }
    }

    @media screen and (max-width: 1000px) {
      .controls-ocntent .contol-buttons {
        font-size: 26px;
        padding: 5px 10px;
        width: 60px;
      }

      bottom: 1px;
    }
  }

  &:not(.muted) {
    .volume-container:hover input {
      &, * {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &.paused .controls, .controls:hover, &.loading .controls {
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, transparent 100%);
    padding: 20px 8px 5px;

    .seek-bar {
      height: 20px;
      margin: 0 10px;

      .bar {
        height: 2px;
      }
    }

    .controls-content .control-buttons {
      height: auto;
      opacity: 1;
      pointer-events: all;
    }
  }
}