@import './mixins.scss';

.taglist {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

    &.main {
        position: absolute;
        left: 5px;
        bottom: 5px;
    }

    li {
        @include tagListItem;

        a {
            text-decoration: none;
            display: inline-block;
        }

        &:not(:last-child) {
            margin-right: 1px;
        }

        &.selected {
            background: #7d7d7d;

            a {
                color: #000;
            }
        }
    }

    &.large a {
        padding: 4px 8px;
    }
}