@import '../components/mixins.scss';

.file-browser {
  .breadcrumbs {
    margin: 0 0 20px;
    padding: 0;
    display: flex;
    list-style-type: none;

    li {
      &:not(:last-child):after {
        content: '>';
        display: inline-block;
      }

      a {
        display: inline-block;
        padding: 0 5px;
        text-decoration: none;
      }
    }
  }

  .taglist {
    margin-bottom: 20px;
    flex-wrap: wrap;

    li {
      position: relative;
      white-space: nowrap;
    }

    .actions {
      position: absolute;
      left: 0;
      transform: translateX(calc(-10px));
      z-index: 2;
      top: 0;
      height: 100%;
      border-left: 1px solid #000;
      display: none;

      a {
        @include tagListItem;

        background: #5a5a5a;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
    }

    li.with-actions:hover .actions {
      display: block;
    }
  }
}