.fav-star {
  display: inline-block;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 3;
  opacity: .2;

  &:hover {
    opacity: 1;
  }

  &.disabled {
    opacity: .25;
    pointer-events: none;
  }

  &.checked {
    opacity: 1;

    svg path {
      color: #c6c600;
    }
  }
}