#app > header {
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 4;
  line-height: var(--app-header-height);
  height: var(--app-header-height);
  transition: box-shadow .5s;
  box-shadow: var(--app-header-shadow);
  background: var(--app-header-bg);
  border-bottom: var(--app-header-border);

  h1 {
    display: inline-block;
    margin: 0;
  }

  #loading-indicator {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    padding: 0 30px;
    text-decoration: none;
  }

  .left, .right {
    position: absolute;
    top: 0;
    display: flex;
  }

  .left {
    left: 20px;
  }

  .right {
    right: 20px;
  }

  ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    height: 100%;
    padding: 0;

    a {
      display: inline-block;
      margin: 0;
      padding: 0 20px;
      text-decoration: none;

      &:hover {
        background: var(--app-hover) !important;
      }
    }

    li.active a {
      background: var(--app-active);
    }
  }
}