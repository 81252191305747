.video-container {
    .seek-bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        .hover-timestamp {
            position: absolute;
            bottom: 20px;
            transform: translateX(-50%);
        }

        .bar {
            background: #fff;
            height: 1px;
            position: relative;
            transition: all .3s;

            .elapsed {
                position: absolute;
                top: 0;
                left: 0;
                background: #f00;
                height: 100%;
                //transition: width .25s;
            }
        }

        &:hover {
            .bar {
                height: 6px !important;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        &.paused .bar {
            height: 6px !important;
        }
    }
}