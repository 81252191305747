.header-item-list {
  position: relative;

  .desktop {
    white-space: nowrap;
  }

  .mobile {
    position: absolute;
    left: 0;
    top: 0;
    display: none !important;

    & > li {
      position: relative;

      ul {
        position: absolute;
        top: 100%;
        left: 0;
        margin: 0;
        padding: 0;
        display: none !important;

        li {
          height: auto;
          display: block;

          a {
            display: block !important;
            width: 100%;
            white-space: nowrap;
            background: var(--app-header-bg);
            text-align: left;
            height: 36px;
            line-height: 36px;
          }
        }
      }
    }

    &.open {
      & > li > ul {
        display: block !important;
      }
    }
  }

  &.mobile {
    .desktop {
      visibility: hidden;
      pointer-events: none;
    }

    .mobile {
      display: block !important;
    }
  }
}