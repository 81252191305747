body {
  --app-font-color: #fff;

  --app-primary: #000;
  --app-secondary: #2a2a2a;
  --app-tertiary: #131313;

  --app-active: #2a2a2a;

  --app-bg: #000;
  --app-hover: #494949;

  --app-header-height: 60px;
  --app-header-bg: var(--app-primary);
  --app-header-shadow: none;
  --app-header-border: 1px solid #3e3e3e;

  --app-top-icon: var(--app-font-color);
  --app-top-icon-bg: var(--app-active);
  --app-top-icon-bg-hover: var(--app-hover);

  --dir-preview-padding: 10px;
  --dir-carpet-bg: #00000080;
  --dir-icon: var(--app-font-color);
  --dir-text: var(--app-font-color);
  --dir-hover-border: var(--app-hover);
  --dir-preview-bg: var(--app-secondary);

  --file-thumb-bg: var(--app-tertiary);
  --file-thumb-file-bg: var(--app-primary);
  --file-border: 2px solid transparent;
  --file-hover-shadow: none;
  --file-hover-border: 2px solid var(--app-hover);

  --video-layer-bg: var(--app-tertiary);
  --video-layer-shadow: none;
  --video-layer-border: 1px solid var(--app-hover);
  --video-layer-video-bg: #000;

  --input-bg-color: #fff;
  --input-font-color: #000;
}

body:not(.dark) {
  --app-font-color: #636363;

  --app-primary: #fff;
  --app-secondary: #efefef;
  --app-tertiary: #e1e1e1;

  --app-active: #efefef;

  --app-hover: #f6f6f6;
  --app-bg: #fff;

  --app-header-bg: #fff;
  --app-header-shadow: 0 0 18px 0;
  --app-header-border: none;

  --dir-carpet-bg: #ffffffb3;

  --file-hover-shadow: 0 0 24px 0 #b3b3b3;

  --video-layer-bg: #fff;
  --video-layer-shadow: 0 0 18px 0;
  --video-layer-border: none;
  --video-layer-video-bg: #fff;
}