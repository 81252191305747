#processlist-indicator {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 4px 10px;
    background: #fff;
    z-index: 99;
    text-decoration: none;
    display: block;
}

body.dark #processlist-indicator {
    background: #000;
}