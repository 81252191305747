@import 'colors';
@import 'components/mixins';

html, body {
    margin: 0;
    padding: 0;
    font-family: Arial, serif;
    font-size: 12px;
}

body {
    padding: calc(var(--app-header-height) + 20px) 20px 20px;

    background: var(--app-bg);

    @media screen and (max-width: 800px) {
        padding: 80px 10px 0;
    }

    &.video-open {
        overflow: hidden;
    }
}

* {
    box-sizing: border-box;
    color: var(--app-font-color);
}

input, select {
    background: var(--input-bg-color);
    color: var(--input-font-color);
}

option {
    color: #000;
}

#app {
    max-height: 100vh;
}

button {
    @include buttonStyle;
}

#scroll-to-top {
    position: fixed;
    right: 10px;
    z-index: 2;
    background: var(--app-top-icon-bg);
    color: var(--app-top-icon);
    display: block;
    padding: 10px;
    text-decoration: none;
    border-radius: 100%;
    bottom: -40px;
    transition: all .25s;

    &:hover {
        background: var(--app-top-icon-bg-hover);
    }
}

body:not(.top) {
    #scroll-to-top {
        bottom: 10px;
    }
}

.directory-dropdown {
    position: absolute;
    left: 20px;
    top: 0;
}

.content {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 80px 20px 20px;
    z-index: 1;

    .photo-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .inner {
            width: auto;
            max-width: 100%;
            max-height: 100%;
            padding: 0;
            border: none;
            margin-top: 1px;
            height: calc(100% - 3px);
        }
    }

    .base.fullscreen {
        .video-container {
            max-height: 100vh;
            margin: 0;
            padding: 0;
        }

        .photo-container img {
            height: 100vh;
            margin: 0;
            padding: 0;
        }
    }
}